import goTo from 'vuetify/lib/services/goto'

/**
 * Router.
 */

import VueRouter from 'vue-router'

import Layout from '@/Layout';

import ArticlePage from './pages/Article';
import AuthorPage from './pages/Author';
import AuthorsPage from './pages/Authors';
import BookPage from './pages/Book';
import BooksPage from './pages/Books';
import BookshelfPage from './pages/Bookshelf';
import BookshelvesPage from './pages/Bookshelves';
import ChartPage from './pages/Chart';
import ChatbotPage from './pages/Chatbot';
import EmbedFavsPage from './pages/Embed/Favourites';
import EmbedTagPage from './pages/Embed/Tag';
import FavouritesPage from './pages/Favourites';
import GenrePage from './pages/Genre';
import GenresPage from './pages/Genres';
import HomePage from './pages/Home';
import IssuePage from './pages/Issue';
import LoginPage from './pages/Login';
import MagazinesPage from './pages/Magazines';
import MagazinePage from './pages/Magazine';
import NewsletterPage from './pages/Newsletter';
import NewslettersPage from './pages/Newsletters';
import NetworkPage from './pages/Network';
import RandomQuotePage from './pages/RandomQuote';
import SearchPage from './pages/Search';
import SettingsPage from './pages/Settings';
import TagPage from './pages/Tag';
import TagsPage from './pages/Tags';
import TopicPage from './pages/Topic';
import TopicsPage from './pages/Topics';
import WebsitePage from './pages/Website';
import WebsitesPage from './pages/Websites';
import WriterPage from './pages/Writer';
import './registerServiceWorker'

const routes = [
  {
    name: 'layout',
    component: Layout,
    path: '/',
    children: [
      { 
        name: 'home', 
        path: '/', 
        component: HomePage,
        meta: {
          auth: true
        }
      },
      { 
        name: 'login', 
        path: '/login', 
        component: LoginPage,
        meta: {
          title: 'Login'
        }
      },
      { 
        name: 'network', 
        path: '/network/:modelType?/:modelId?', 
        component: NetworkPage,
        meta: {
          title: 'Network Explorer',
          auth: true
        }
      },
      /**
       * Articles
       */
      { 
        name: 'article', 
        path: '/article/:articleId', 
        component: ArticlePage,
        meta: {
          auth: true
        }
      },
      /**
       * Authors.
       */
      { 
        name: 'author', 
        path: '/author/:authorId', 
        component: AuthorPage,
        meta: {
          auth: true
        }
      },
      { 
        name: 'authors', 
        path: '/authors', 
        component: AuthorsPage,
        meta: {
          title: 'Authors',
          auth: true
        }
      },
      /**
       * Books.
       */
      { 
        name: 'book', 
        path: '/book/:bookId', 
        component: BookPage,
        meta: {
          auth: true
        }
      },
      { 
        name: 'books', 
        path: '/books', 
        component: BooksPage,
        meta: {
          title: 'Books',
          auth: true
        }
      },
      { 
        name: 'chart', 
        path: '/chart', 
        component: ChartPage,
        meta: {
          title: 'Chart',
          auth: true
        }
      },
      /**
       * Websites.
       */
      {
        name: 'website',
        path: '/website/:websiteId',
        component: WebsitePage,
        meta: {
          title: 'Website',
          auth: true
        }
      },
      {
        name: 'websites',
        path: '/websites',
        component: WebsitesPage,
        meta: {
          title: 'Websites',
          auth: true
        }
      },
      /**
       * Newsletters.
       */
      {
        name: 'newsletter',
        path: '/newsletter/:newsletterId',
        component: NewsletterPage,
        meta: {
          title: 'Newsletter',
          auth: true
        }
      },
      {
        name: 'newsletters',
        path: '/newsletters',
        component: NewslettersPage,
        meta: {
          title: 'Newsletters',
          auth: true
        }
      },
      /**
       * Bookshelves.
       */
      { 
        name: 'bookshelf', 
        path: '/bookshelf/:bookshelfId', 
        component: BookshelfPage,
        meta: {
          auth: true
        }
      },
      { 
        name: 'bookshelves', 
        path: '/bookshelves', 
        component: BookshelvesPage,
        meta: {
          title: 'Bookshelves',
          auth: true
        }
      },
      /**
       * Favs.
       */
      { 
        name: 'favourites', 
        path: '/favourites', 
        component: FavouritesPage,
        meta: {
          title: 'Favourites',
          auth: true
        }
      },
      /**
       * Genres.
       */
      { 
        name: 'genre', 
        path: '/genre/:genreId', 
        component: GenrePage,
        meta: {
          auth: true
        }
      },
      { 
        name: 'genres', 
        path: '/genres', 
        component: GenresPage,
        meta: {
          title: 'Genres',
          auth: true
        }
      },
      /**
       * Issues.
       */
      { 
        name: 'issue', 
        path: '/issue/:issueId', 
        component: IssuePage,
        meta: {
          auth: true
        }
      },
      /**
       * Magazines.
       */
      { 
        name: 'magazines', 
        path: '/magazines', 
        component: MagazinesPage,
        meta: {
          title: 'Magazines',
          auth: true
        }
      },
      { 
        name: 'magazine', 
        path: '/magazine/:magazineId', 
        component: MagazinePage,
        meta: {
          auth: true
        }
      },
      { 
        name: 'tags', 
        path: '/tags', 
        component: TagsPage,
        meta: {
          title: 'Tags',
          auth: true
        }
      },
      { 
        name: 'tag', 
        path: '/tag/:tagId', 
        component: TagPage,
        meta: {
          auth: true
        }
      },
      /**
       * Topics.
       */
      { 
        name: 'topics', 
        path: '/topics', 
        component: TopicsPage,
        meta: {
          title: 'Topics',
          auth: true
        }
      },
      { 
        name: 'topic', 
        path: '/topic/:topicId', 
        component: TopicPage,
        meta: {
          auth: true
        }
      },
      /**
       * Others.
       */
      {
        name: 'chatbot',
        path: '/chatbot',
        component: ChatbotPage,
        meta: {
          auth: true
        }
      },
      {
        name: 'random-quote',
        path: '/quotes/random',
        component: RandomQuotePage,
        meta: {
          auth: true
        }
      },
      {
        name: 'writer',
        path: '/writer',
        component: WriterPage,
        meta: {
          auth: true
        }
      },
      {
        name: 'search',
        path: '/search',
        component: SearchPage,
        meta: {
          auth: true
        }
      },
      {
        name: 'settings',
        path: '/settings',
        component: SettingsPage,
        meta: {
          auth: true
        }
      },
    ] 
  },
  /**
   * Embed.
   */
  { 
    name: 'embed.tag', 
    path: '/tag/:tagId/embed', 
    component: EmbedTagPage,
    meta: {}
  },
  { 
    name: 'embed.favs', 
    path: '/favourites/embed', 
    component: EmbedFavsPage,
    meta: {}
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if ( to.hash && 
         document.getElementById(to.hash.replace('#','')) ) 
    {
      scrollTo = to.hash
    } 
    else if (savedPosition) 
    {
      scrollTo = savedPosition.y
    }

    return goTo(scrollTo)
  },
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('user')

  // auth check
  if (to.matched.some(record => record.meta.auth) && !loggedIn) {
    console.log('ko')
    next('/login')
    return
  }

  // page title
  let pageTitle = 'Booklist', 
      nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  if(nearestWithTitle)
    pageTitle = nearestWithTitle.meta.title + ` - ${pageTitle}`

  document.title = pageTitle

  next()

})

export default router