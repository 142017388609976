const optional = require('@lessworkjs/optional')

import API from './api'

import moment from 'moment'

const utils = {
  
	created: function () {},
  
	computed: {

		isMobile()
		{
			return (this.$mq=='sm')
		},

		modalMaxWidth()
		{
			return this.$mq=='sm' ? '' : '50vw'
		}

	},
	
	methods: {

		/**
		 * Format a date string using moment.
		 */
		formatDate(value="", format="DD/MM/YYYY")
		{
			return moment(String(value)).format(format)
		},

		/**
		 * Get a model class utility from string.
		 */
		getModelType( modelType = "" )
		{
			var model = null

			// is node.id ?
			if( modelType.indexOf('-') >= 0 )
				modelType = modelType.split('-').shift()

			switch(modelType){
				case 'article':
				case 'App\\Article':
				case 'App\\Models\\Article':
					model = {
						api: API.articles,
						name: 'article'	
					}
					break;
				case 'book':
				case 'App\\Book':
				case 'App\\Models\\Book':
					model = {
						api: API.books,
						name: 'book'	
					}
					break;
				case 'issue':
				case 'App\\Issue':
				case 'App\\Models\\Issue':
					model = {
						api: API.issues,
						name: 'issue'	
					}
					break;
				case 'magazine':
				case 'App\\Magazine':
				case 'App\\Models\\Magazine':
					model = {
						api: API.magazines,
						name: 'magazine'	
					}
					break;
				case 'note':
				case 'App\\Note':
				case 'App\\Models\\Note':
					model = {
						api: API.notes,
						name: 'note'	
					}
					break;
				case 'quote':
				case 'App\\Quote':
				case 'App\\Models\\Quote':
					model = {
						api: API.quotes,
						name: 'quote'	
					}
					break;
				case 'topic':
				case 'App\\Topic':
				case 'App\\Models\\Topic':
					model = {
						api: API.topics,
						name: 'topic'	
					}
					break;
				case 'website':
				case 'App\\Website':
				case 'App\\Models\\Website':
					model = {
						api: API.websites,
						name: 'website'	
					}
					break;
			}

			return model
		},

		optional(value, cb)
		{
			return optional(value, cb)
		}
	}
  
}

export default utils