import Api from '../api'
import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    favourites: [],
    growler: {},
    isLoading: false,
    user: null,
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },

  mutations: {
    growl (state, data) {
      /**
       * @todo import from config .js
       */
      const defaults = {
        color: 'info',
        status: true,
        text: ''
      }

      if( typeof data == 'string' )
        data = { text: data }
      
      state.growler = { 
        ...defaults,
        ...data 
      }

    },

    setLoading (state, status = true) {
      state.isLoading = status
    },

    setUserData (state, userData) {
      state.user = userData
      localStorage.setItem('user', JSON.stringify(userData))
      axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
    },

    clearUserData () {
      localStorage.removeItem('user')
      localStorage.removeItem('favourites')
      location.reload()
    },

    setUserFavourites(state, userFavourites) {
      state.favourites = userFavourites
      localStorage.setItem('favourites', JSON.stringify(userFavourites))
    }
  },

  actions: {
    login ({ commit }, credentials) {
      return Api.login(credentials)
        .then(({ data }) => {
          commit('setUserData', data)
        })
    },

    logout ({ commit }) {
      commit('clearUserData')
    },

    loadFavourites({ commit }) {
      Api.favourites.list()
         .then((res)=>{
          commit('setUserFavourites', res.data)
         })
         .catch((error)=>{
          console.log(error.response)
         })
    }
  },

  getters : {
    growler: state => state.growler,
    isLoading: state => state.isLoading,
    isLogged: state => !!state.user,
    user: (state)=>{
      return state.user ? state.user.user : null
    },
    appVersion: (state) => {
      return state.packageVersion
    },
    favourites: (state)=>{
      return state.favourites ? state.favourites : []
    },
  }
})